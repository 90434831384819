import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import CookieConsent from "react-cookie-consent"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[
            `bart`,
            `bartek`,
            `widlarz`,
            `bart widlarz`,
            `bartek widlarz`,
            `bartlomiej widlarz`,
            `bartłomiej widlarz`,
          ]}
        />
        <CookieConsent
          style={{
            background:
              "linear-gradient(to bottom, grey , #2B373B, #2B373B, #2B373B, #2B373B, #2B373B)",
            fontFamily: `Montserrat`,
            fontSize: "13px",
          }}
          buttonText="ok"
          contentStyle={{ flex: "1 200px", margin: "10px" }}
          buttonStyle={{
            borderRadius: "15px",
            backgroundColor: "#007acc",
            color: "#ffffff",
            fontSize: "13px",
            fontFamily: `Montserrat`,
          }}
        >
          This website uses cookies.
        </CookieConsent>
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
